<template>
  <div style="margin-top: -70px;"> <!-- mt--3 -->
    <!-- TEN NAV BEDZIE INNY, TYPOWO DLA DASHBOARDU, LATWY DOSTEP DLA UZYTKOWNIKA, SCHOWANE ZA AUTH -->
    <div class="dashboard-menu mt-4" style="padding-top: 22px;">
      <ul class="list-unstyled">
        <li class="dashboard-menu-item">
          <router-link to="/dashboard-statistics" class="text-decoration-none">
            <button class="dashboard-menu-icon">
              <font-awesome-icon icon="chart-pie" style="color: blue;" class="menu-icon" />
              <span class="dashboard-menu-text ps-2"> Statistics</span>
              <span class="dashboard-menu-text-under-icon">Statistics</span>
            </button>
          </router-link>
        </li>
        <li class="dashboard-menu-item">
          <router-link to="/dashboard-portfolio" class="text-decoration-none">
            <button class="dashboard-menu-icon">
              <font-awesome-icon icon="house-user" style="color: limegreen;" class="menu-icon" />
              <span class="dashboard-menu-text ps-2"> Property Portfolio</span>
              <span class="dashboard-menu-text-under-icon">Portfolio</span>
            </button>
          </router-link>
        </li>
        <li class="dashboard-menu-item">
          <router-link to="/dashboard-services" class="text-decoration-none">
            <button class="dashboard-menu-icon">
              <font-awesome-icon icon="users-cog" style="color: darkorange;" class="menu-icon" />
              <span class="dashboard-menu-text ps-2"> Services</span>
              <span class="dashboard-menu-text-under-icon">Services</span>
            </button>
          </router-link>
        </li>
        <!-- SKIP FAVOURITES FOR TIME BEING AS WE HAVE THE FAVOURITES BAR BELOW -->
<!--        <li class="dashboard-menu-item">-->
<!--          <router-link to="/dashboard-favourites" class="text-decoration-none">-->
<!--            <button class="dashboard-menu-icon">-->
<!--              <font-awesome-icon icon="heart" style="color: red;" />-->
<!--              <span class="dashboard-menu-text ps-2"> FAVOURITES</span>-->
<!--            </button>-->
<!--          </router-link>-->
<!--        </li>-->
        <li class="dashboard-menu-item">
          <router-link to="/dashboard-user-messages" class="text-decoration-none">
            <button class="dashboard-menu-icon">
              <font-awesome-icon icon="comment" style="color: mediumpurple;" class="menu-icon" />
              <span class="dashboard-menu-text ps-2"> User Messages</span>
              <span class="dashboard-menu-text-under-icon">Messages</span>
            </button>
          </router-link>
        </li>
        <li class="dashboard-menu-item">
          <router-link to="/dashboard-bookings" class="text-decoration-none">
            <button class="dashboard-menu-icon">
              <font-awesome-icon icon="calendar-day" style="color: red;" class="menu-icon" />
              <span class="dashboard-menu-text ps-2"> Bookings</span>
              <span class="dashboard-menu-text-under-icon">Bookings</span>
            </button>
          </router-link>
        </li>
<!--        <li class="dashboard-menu-item">-->
<!--          <router-link to="/dashboard-user-calendar" class="text-decoration-none">-->
<!--            <button class="dashboard-menu-icon">-->
<!--              <font-awesome-icon icon="calendar-day" style="color: red;"/>-->
<!--              <span class="dashboard-menu-text ps-2"> User Calendar</span>-->
<!--            </button>-->
<!--          </router-link>-->
<!--        </li>-->
        <li class="dashboard-menu-item">
          <router-link to="/dashboard-reviews" class="text-decoration-none">
            <button class="dashboard-menu-icon">
              <font-awesome-icon icon="star" class="reviews-icon menu-icon" style="color: yellow;" />
              <span class="dashboard-menu-text ps-2"> Reviews</span>
              <span class="dashboard-menu-text-under-icon">Reviews</span>
            </button>
          </router-link>
        </li>
        <li class="dashboard-menu-item">
          <router-link to="/dashboard-user-settings" class="text-decoration-none">
            <button class="dashboard-menu-icon">
              <font-awesome-icon icon="cog" style="color: slategrey;" class="menu-icon" />
              <span class="dashboard-menu-text ps-2"> User Settings</span>
              <span class="dashboard-menu-text-under-icon">Settings</span>
            </button>
          </router-link>
        </li>
      </ul>
    </div>
    <main class="mt-3 mb-3 dashboard"> <!-- ms-1 me-1 -->
      <p class="dashboard-heading m-0">Dashboard - {{ checkRoute }}</p>
      <div class="ps-1 pe-1">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name:"dashboard-layout",
  data(){
    return {
      user:this.$store.state.auth.user
    }
  },
  methods:{
    ...mapActions({
      signOut:"auth/logout"
    }),
    async logout(){
      await axios.post('api/logout').then(({data})=>{
        this.signOut()
        this.$router.push({name:"login"})
      })
    }
  },
  computed: {
    checkRoute() {
      let routeInfo = this.$route;
      let result = '';
      if (routeInfo && routeInfo.name) {
        switch(routeInfo.name) {
          case 'dash-statistics':
            result = 'Statistics';
            break;
          case 'dash-portfolio':
            result =  'Property Portfolio';
            break;
          case 'dash-services':
            result =  'Services';
            break;
          case 'dash-user-settings':
            result =  'User Settings';
            break;
          case 'dash-user-messages':
            result =  'User Messages';
            break;
          case 'dash-bookings':
            result =  'Bookings';
            break;
          case 'dash-reviews':
            result =  'Reviews';
            break;
          default:
            result =  '';
            break;
        }
      }
      return result;
    }
  }
}
</script>

<style scoped lang="scss">

.reviews-icon path {
  stroke: #3d3d3d;
  stroke-width: 20;
}

.dashboard-menu {
  position: fixed;
  left: 0;
  //background-color: white;
  border-radius: 0 10px 10px 0;
  //border: 1px solid #ced4da;
  //box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  padding: 5px;
  z-index: 1003;
  width: auto;
}

.dashboard-menu-item {
  position: relative;
  margin: 10px 0 10px 0;
  display: block;
  text-align: start;
  width: auto;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
}

.dashboard-menu-item:hover .dashboard-menu-icon {
  margin-left: 7px;
  width: auto;
  background-color: white;
  color: black;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
}

.dashboard-menu-item:hover .menu-icon {
  margin-left: 5px;
}

.dashboard-menu-item:hover .dashboard-menu-text  {
  display: inline-block;
  visibility: visible;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  opacity: 1;
  width: max-content;
  min-width: max-content;
  max-width: max-content;
  white-space: nowrap;
}

.dashboard-menu-text {
  display: none;
  width: 0;
  max-width: 0;
  visibility: hidden;
  white-space: nowrap;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
  opacity: 0;
  color: black;
  letter-spacing: 1px;
}

.dashboard-menu-text-under-icon {
  padding: 0;
  margin: 0;
  font-size: 7px;
  display: block;
  text-align: center;
  visibility: visible;
  opacity: 1;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
}

.dashboard-menu-item:hover .dashboard-menu-text-under-icon {
  //display: none;
  visibility: hidden;
  text-align: start;
  width: 0;
  height: 0;
  opacity: 0;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  -moz-transition: 0.5s;
}

.dashboard-menu-icon {
  text-align: center;
  vertical-align: middle;
  min-width: 35px;
  width: 45px;
  height: 45px;
  display: inline-block;
  //padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 10px;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  overflow: hidden;
}

.dashboard {
  border-radius: 10px;
  background: white;
  $border: 2px;
  position: relative;
  padding-bottom: 1em;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -$border; /* !importanté */
    border-radius: inherit; /* !importanté */
    background: linear-gradient(to right, rgba(25, 181, 254, 0.5), rgba(128, 0, 128, 0.5)); // #b209b2 #19b5fe
  }

  p.dashboard-heading {
    font-size: 16px;
    color: white;
    border-radius: 10px 10px 0 0;
    background: linear-gradient(to right, rgba(25, 181, 254, 0.5) 25%, rgba(128, 0, 128, 0.5));
    //background: rgba(128, 0, 128, 1);
    padding: .5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    text-shadow: 2px 1px #3c3c3c;
  }
}

</style>